
  import { Component } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import LinkedAuto from '@/components/forms/fields/LinkedAuto.vue'
  import { plainToInstance } from 'class-transformer'
  import { Form } from '@/entities/public/Resource/metadata'
  import { Deal, Lead } from '@/entities/crm'
  import InitialFields from '@/components/forms/fields/InitialFields.vue'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import StockPrices from '@/components/toolkit/details/row/custom/StockPrices.vue'
  import AutoHeader from '@/components/toolkit/details/row/expandable/auto/header.vue'
  import Simple from '@/components/toolkit/details/row/simple.vue'
  import { Consignment, Negotiation } from '@/entities/purchase'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { Process } from '@/entities/settings'
  import { fixPrice, stringifySafe } from '@/utils/general'
  import { PurchaseView } from '@/components/forms/view/PurchaseView'
  import { Payment, PaymentOrder } from '@/entities/finance'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import GLoading from '@/components/core/GLoading.vue'
  import logOut from '@/components/LogOut.vue'
  import AutoResume from '@/components/forms/AutoResume.vue'
  import { Attribute } from '@/entities/vehicle'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import GCostField from '@/components/core/input/GCostField.vue'
  import { Debounce } from '@/utils/decorators'

@Component({
  components: {
    GCostField,
    GRadioButton,
    AutoResume,
    logOut,
    GLoading,
    GAlert,
    GFiles,
    Simple,
    StockPrices,
    FieldTitle,
    LinkedPerson,
    InitialFields,
    LinkedAuto,
    BaseForm,
    AutoHeader,
  },
})
  export default class ConsignmentForm extends PurchaseView {
  negotiation = plainToInstance(Negotiation, {})
  lead = plainToInstance(Lead, {})
  consignment = plainToInstance(Consignment, {})
  existDocument = false
  process: Process = plainToInstance(Process, {})
  deal: Deal = plainToInstance(Deal, {})
  attributes: Attribute[] = []
  payments: Array<Payment> = []
  paymentOrder: PaymentOrder = plainToInstance(PaymentOrder, {})
  panels = [0]
  purchase = {
    open: false,
    title: '',
    item: null,
  }

  consignmentMetadata = null
  title = ''
  idProcess = null
  showDetail = false
  fileInfo = null

  declare $refs: {
    form: HTMLFormElement
  };

  metadata = {}
  radioButtonMessage = {
    hasCost: '',
  }

  formData = {
    mileage: null,
    hasCost: null,
    cost: null,
    contractRetirement: [],
    contractAnnulled: [],
    owners: [],
    signers: [],
    payments: [],
  }

  section = {
    contract: false,
    payment: false,
  }

  fields = {
    contractRetirement: {
      properties: {
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
      },
      rules: this.fieldRequired,
    },
    contractAnnulled: {
      properties: {
        accept: '',
        fileTypeId: null,
        multiple: false,
        name: '',
      },
      rules: this.fieldRequired,
    },
  }

  async mounted () {
    this.metadata = {}
    this.loadingForm = true
    this.loadingCar = true
    await this.setMetadata()

    const { id, uid, model, title, consignmentMetadata } = this

    if ((!isNaN(uid) && (['PurchaseOrder', 'ConsignmentForm'].includes(model) || model === '')) || !isNaN(id)) {
      const purchaseId = id || uid
      await this.getPurchaseInfo(purchaseId)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(consignmentMetadata, title, Boolean(this.consignment?.id))
    }

    await this.setTheBackup()
    await this.setPayments()
    await this.setDetails()

    this.loadingForm = false
  }

  async setTheBackup () {
    const { backup } = this
    if (!backup) return

    if (backup && 'consignmentForm' in backup && backup.consignmentForm) {
      this.formData = backup.consignmentForm
    }
  }

  async setPayments () {
    const { consignment } = this

    if (!consignment?.id) return
    this.formData.payments = await this.fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { id_process_record: { _eq: this.consignment.id } },
          { id_process: { _eq: this.idProcess } },
          { id_payment_recipient: { _is_null: true } },
          { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
        ],
      },
      force: true,
    })
  }

  cancelPurchase () {
    const { formData } = this
    this.setBackup(null)
    const buyFile = formData?.buyFile?.length ? [...formData?.buyFile] : []
    const notarizedAuthorization = formData?.notarizedAuthorization?.length ? [...formData?.notarizedAuthorization] : []
    const legalReport = formData?.legalReport?.length ? [...formData?.legalReport] : []
    this.close([...buyFile, ...notarizedAuthorization, ...legalReport])
  }

  async getPurchaseInfo (id) {
    this.consignment = await this.fetchData({
      query: { name: 'fetch', model: 'Consignment', params: { id } },
      force: true,
    })

    const document = (await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: { id_process_record: { _eq: this.consignment.id } },
      force: true,
    }))[0]

    const { interveners = [] } = document
    this.formData.signers = interveners?.filter(intervener => intervener.field.name === 'sale_representative').map(intervener => intervener.person)

    const appraisal = await this.fetchData({
      query: { name: 'find', model: 'Appraisal' },
      filter: {
        inspection: { negotiation: { id: { _eq: this.consignment?.negotiation?.id } } },
      },
    })

    const deal = await this.fetchData({
      query: { name: 'find', model: 'Deal' },
      filter: { appraisals: { id: { _eq: appraisal[0]?.id } } },
    })

    this.deal = await this.fetchData({
      query: { name: 'fetch', model: 'Deal', params: { id: deal[0]?.id } },
      force: true,
    })

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { process: { table_name: { _eq: 'purchase_order' } } },
    })
    this.fileInfo = fileInfo

    await this.setMileage()
    this.setProperties(fileInfo, 'consignment_withdrawal', 'contractRetirement')
    this.setProperties(fileInfo, 'purchase_sale_cancellation', 'contractAnnulled')
    await this.getFiles()
    await this.getNegotiationInfo(this.consignment.negotiation.id)
  }

  async getFiles () {
    const { consignment, idProcess } = this

    this.formData.contractRetirement = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: consignment.id } },
          { parameter: { process: { id: { _eq: idProcess } } } },
          { parameter: { name: { _eq: 'consignment_withdrawal' } } },
        ],
      },
      force: true,
    })

    this.formData.contractAnnulled = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: consignment.id } },
          { parameter: { process: { id: { _eq: idProcess } } } },
          { parameter: { name: { _eq: 'purchase_sale_cancellation' } } },
        ],
      },
      force: true,
    })
  }

  async getNegotiationInfo (id) {
    if (!id) return
    const negotiation = await this.fetchData({
      query: { name: 'fetch', model: 'Negotiation', params: { id } },
      force: true,
    })

    this.formData.owners = negotiation?.inspection?.appraisal?.deal?.ownership?.owners?.map(owner => owner.person)

    this.consignment.owners = this.formData.owners
  }

  async setMileage () {
    const km = (await this.fetchData({
      query: { name: 'find', model: 'Component' },
      filter: { slug: { _eq: 'mileage' } },
    }))[0]

    const document = (await this.fetchData({
      query: { name: 'find', model: 'DocumentType' },
      filter: { _and: [{ name: { _eq: 'consignment_withdrawal_contract' } }, { process: { id: { _eq: this.idProcess } } }] },
    }))[0]

    const existDocument = (await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: {
        _and: [
          { id_document_type: { _eq: document?.id } },
          { id_process_record: { _eq: this.consignment.id } },
        ],
      },
      force: true,
    }))[0]

    if (existDocument?.id) {
      const exist = (await this.fetchData({
        query: { name: 'find', model: 'DealAutoAttribute', sort: { id: 'desc' } },
        filter: {
          _and: [
            { id_process_record: { _eq: this.consignment.id } },
            { id_process: { _eq: this.idProcess } },
            { id_deal: { _eq: this.deal.id } },
            { id_component: { _eq: km.id } },
          ],
        },
        force: true,
      }))[0]
      this.formData.mileage = exist?.value
      this.formData.hasCost = existDocument?.metadata?.hasCost
      this.formData.cost = existDocument?.metadata?.cost
      this.existDocument = Boolean(existDocument?.id)
    }
  }

  async setMetadata () {
    const { metadata } = this.getForm('Consignment', 'staff_consignment')
    const { form } = metadata as Form
    this.consignmentMetadata = metadata
    this.title = form.title

    await this.setFilesFieldsData()
  }

  async setFilesFieldsData () {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'purchase_order' } },
    })

    const { id } = process[0] as Record<string, any>

    this.idProcess = id
  }

  async setDetails () {
    const { consignmentMetadata, consignment, negotiation } = this
    const lead = negotiation?.inspection?.deal?.lead

    if (lead?.id) {
      const deals = await this.fetchData({
        query: { name: 'findLite', model: 'Deal' },
        filter: { id_lead: { _eq: lead.id } },
        force: true,
      })
      consignment.dealSale = deals?.find(deal => deal.isSale)
    }

    this.metadata = {
      data: consignment,
      metadata: consignmentMetadata,
    }
    this.showDetail = Boolean(consignment?.id)
  }

  async send () {
    const errorMessage = Boolean(this.paymentError?.length) && this.formData.hasCost

    if (errorMessage) {
      this.section.payment = true
      this.panels = [0]
    }

    this.sectionValidation()
    if (!this.$refs.form.validate() || this.isValidCost() || errorMessage) {
      await this.setFieldError()
      return
    }
    const { idProcess, consignment, formData } = this
    const document = await this.fetchData({
      query: { name: 'find', model: 'DocumentType' },
      filter: { _and: [{ name: { _eq: 'consignment_withdrawal_contract' } }, { process: { id: { _eq: idProcess } } }] },
    })

    this.loadingForm = true
    if (this.displayMessage) {
      await this.handleDocument(document, consignment)
      await this.insertOrUpdateMileage(consignment, formData.mileage)
      await this.close()
      return
    }

    const hasFiles = formData.contractAnnulled.length && formData.contractRetirement.length

    if (!hasFiles) {
      await this.insertDocumentConsignmentWithdrawalContract(this, consignment.id, document[0])
      await this.pushData({
        model: 'Consignment',
        fields: {
          id: consignment.id,
          id_process_status: this.purchaseOrderStatus.pickupActive[0].id,
        },
      })
      await this.insertOrUpdateMileage(consignment, formData.mileage)
    } else {
      await this.handleConsignment(document)
    }
    await this.close()
  }

  async handleConsignment (document) {
    const { formData, fields, idProcess, consignment } = this
    if (formData.contractAnnulled.length) {
      await this.handleFileType(formData.contractAnnulled, fields.contractAnnulled, idProcess, consignment.id)
    }
    if (formData.contractRetirement.length) {
      await this.handleFileType(formData.contractRetirement, fields.contractRetirement, idProcess, consignment.id)
    }

    await this.pushData({
      model: 'Consignment',
      fields: {
        id: consignment.id,
        id_process_status: this.purchaseOrderStatus.pickupToConfirm[0].id,
      },
    })

    const existDocument = (await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: {
        _and: [
          { id_document_type: { _eq: document[0]?.id } },
          { id_process_record: { _eq: consignment.id } },
        ],
      },
      force: true,
    }))[0]

    await this.pushData({
      model: 'Document',
      fields: {
        id: existDocument.id,
        id_process_status: this.statusDocumentLegalReview[0].id,
      },
    })
  }

  async handleDocument (document, consignment) {
    const existDocument = (await this.fetchData({
      query: { name: 'find', model: 'Document' },
      filter: {
        _and: [
          { id_document_type: { _eq: document[0]?.id } },
          { id_process_record: { _eq: consignment.id } },
        ],
      },
      force: true,
    }))[0]

    await this.pushData({
      model: 'Document',
      fields: {
        id: existDocument.id,
        metadata: {
          ...existDocument?.metadata,
          hasCost: this.formData.hasCost,
          cost: this.formData.cost,
          mileage: this.formData.mileage,
        },
      },
    })
  }

  async insertOrUpdateMileage (consignment, mileage) {
    const { deal } = this

    const km = (await this.fetchData({
      query: { name: 'find', model: 'Component' },
      filter: { slug: { _eq: 'mileage' } },
    }))[0]

    const exist = (await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter: {
        _and: [
          { id_process_record: { _eq: consignment.id } },
          { id_process: { _eq: this.idProcess } },
          { id_deal: { _eq: deal.id } },
          { id_component: { _eq: km.id } },
        ],
      },
      force: true,
    }))[0]

    if (!exist?.id) {
      await this.pushData({
        model: 'DealAutoAttribute',
        fields: {
          id_process_record: consignment.id,
          value: mileage,
          id_process: this.idProcess,
          id_deal: deal.id,
          id_component: km.id,
        },
      })
    } else {
      await this.pushData({
        model: 'DealAutoAttribute',
        fields: {
          id: exist.id,
          value: mileage,
        },
      })
    }
  }

  get change () {
    const { formData, panels, section, consignment, isLoading } = this

    return stringifySafe([formData, panels, section, consignment, isLoading])
  }

  setProperties (fileInfo, fileTypeName, fieldKey) {
    if (!fileInfo?.length) return
    const info = fileInfo.find(fileParameter => fileParameter.name === fileTypeName)
    if (info) {
      this.fields[fieldKey].properties.accept = info.fileType.mimes
      this.fields[fieldKey].properties.multiple = info.multiple
      this.fields[fieldKey].properties.fileTypeId = info.fileType.id
      this.fields[fieldKey].properties.name = info.name
      this.fields[fieldKey].properties.label = info.description
      this.fields[fieldKey].properties.required = info.required
      this.fields[fieldKey].properties.icon = info.fileType.icon
    }
  }

  loadingFile (flag) {
    this.isUploadingFile = flag
  }

  isValidCost () {
    const { formData: { hasCost } } = this

    if (hasCost === null) {
      this.radioButtonMessage.hasCost = 'Campo requerido'
      return true
    }
    this.radioButtonMessage.hasCost = ''
    return false
  }

  getIcon (item) {
    if (item?.closingReason?.name === 'successful') {
      return 'mdi-eye'
    }
    return 'mdi-pencil-outline'
  }

  sectionValidation () {
    const {
      formData: {
        contractRetirement,
        contractAnnulled,
      },
    } = this

    this.section.contract = !contractRetirement?.length || !contractAnnulled?.length

    if (this.section.contract) {
      const isContract = this.panels.some(panel => panel === 0)
      if (!isContract) {
        this.panels.push(0)
      }
    }
  }

  saveBackup () {
    const { backup, formData, panels, section, consignment } = this

    if (backup) {
      backup.consignmentForm = { ...formData, id: consignment.id }
      backup.panels = panels
      backup.section = section
      backup.paymentForm = null
    } else {
      this.setBackup({ consignmentForm: { ...formData, id: consignment.id }, panels, section })
    }
  }

  async goToPayments () {
    const { deal } = this
    if (this.backup?.paymentForm) {
      this.backup.paymentForm = null
    }
    this.saveBackup()

    const idDeal = deal?.id ? deal?.id.toString() : 'create'
    await this.$router.push({
      name: 'generic-payment',
      params: { parentModel: 'Deal', parentId: idDeal, id: 'create' },
    })
  }

  get disabledApproved () {
    const { consignment } = this
    return consignment?.status?.isApproved || consignment?.status?.isToConfirm
  }

  get purchaseIsApproved () {
    return false
  }

  get paymentError () {
    const { formData: { payments, cost } } = this
    if (!payments) return ''

    return this.paymentValid(payments, cost) ? '' : `La suma de los pagos debe ser igual al costo de retiro ${fixPrice(cost)}`
  }

  get alertMessagePaymentUpdating () {
    const { formData: { payments } } = this

    return payments?.some(payment => payment?.status?.isToUpdating) ? 'Pagos por actualizar' : ''
  }

  get alertMessagePaymentPending () {
    const { formData: { payments } } = this

    return payments?.some(payment => payment?.status?.isPending) ? 'Pagos por validar' : ''
  }

  get isLoading () {
    const { loadingForm, loadingCar } = this

    return loadingForm || loadingCar
  }

  get hasCostResponse () {
    const { formData } = this

    return formData.hasCost !== null && formData.hasCost !== undefined
  }

  get displayMessage () {
    const { hasCostResponse, formData } = this

    return hasCostResponse && formData.payments?.length && formData.payments.some(payment => payment?.status?.isPending)
  }

  get disableFieldsAfterPay () {
    const { formData, paymentError } = this

    if (!formData?.payments?.length) {
      return false
    }

    return formData?.payments?.every(payment => !payment?.status?.isPending && !payment?.status?.isToUpdating) && Boolean(!paymentError?.length)
  }

  paymentValid (payments, cost) {
    const suma: number = payments.reduce((total, item) => {
      return total + Number(item.amount)
    }, 0)

    return suma === Number(cost)
  }

  async editPayment (item) {
    const { deal } = this
    this.saveBackup()

    const idDeal = deal?.id ? deal?.id.toString() : 'create'
    await this.$router.push({
      name: 'generic-payment',
      params: { parentModel: 'Deal', parentId: idDeal, uid: item.id },
    })
  }

  get isConsignmentPickupToUpdate () {
    const { consignment } = this

    return consignment?.status?.name === 'pickup_to_update'
  }

  @Debounce(5000)
  setLoadingCar () {
    this.loadingCar = !this.loadingCar
  }
  }
